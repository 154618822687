import React from 'react'
import { PageProps } from 'gatsby'

import * as styles from '../../../styles/pages/solutionsPage.module.scss'

import { SEO } from '../../../components/SEO'
import { SuccessCases } from '../../../components/SuccessCases'
import { AboutUs } from '../../../components/AboutUs'
import { Divider } from '../../../components/Divider'
import { Statistics } from '../../../components/Statistics'
import { Slider } from '../../../components/Slider'
import { SectionTitle } from '../../../components/SectionTitle'
import { Layout } from '../../../components/layout'
import {
  MethodologySection,
  FeaturesSection,
} from '../../../components/SolutionsPages'

import banner1 from '../../../images/pages/controladoria/controladoria1.jpeg'
import banner2 from '../../../images/pages/controladoria/controladoria2.jpeg'
import {
  faChartPie,
  faSearchDollar,
  faMoneyBillWave,
  faBookOpen,
} from '@fortawesome/free-solid-svg-icons'

const slides = [
  {
    title: <>AS MELHORES PRÁTICAS PARA PLANEJAMENTO E GESTÃO EMPRESARIAL</>,
    description: (
      <>
        Através da estruturação do processo de controladoria para mensurar e
        alavancar resultados
      </>
    ),
    backgroundUrl: banner1,
    sameOrigin: true,
    link: `#contato`,
    linkText: `Fale Conosco`,
  },
  {
    title: <>MELHORE SEUS RESULTADOS ATRAVÉS DA GESTÃO COMPARTILHADA</>,
    description: (
      <>
        Através da criação de metas, planos de meritocracia, apuração de
        resultados e planos de ação consistentes
      </>
    ),
    backgroundUrl: banner2,
    sameOrigin: true,
    link: `#contato`,
    linkText: `Fale Conosco`,
  },
]

const cases = [
  {
    title: `Diman Serviços Mecânicos`,
    text: `Implantação da rotina de gestão através da reunião mensal de análise
    de resultados. Realizadas mudanças na gestão através da classificação
    adequada de gastos, sistematização de relatórios e planejamento
    orçamentário.`,
  },
  {
    title: `TPC Law`,
    text: `Implantação da rotina de gestão para controles operacionais e
    tomada de decisões pautada em KPIs, possibilitando um crescimento
    seguro e sustentável.`,
  },
  {
    title: `Tecca Engenharia de Sistemas`,
    text: `Após o projeto foram fechados dois grandes negócios, resultado de uma
    maior assertividade na formação do preço de venda. Trabalho feito
    com foco nos custos diretos e créditos e débitos de impostos.`,
  },
]

const Controladoria: React.FC<PageProps> = ({ location }) => {
  const featuresItems = [
    {
      faIcon: faChartPie,
      title: `Gestão por Indicadores`,
      description: (
        <>
          Criação de uma estrutura de relatórios gerenciais (indicadores) que
          representem o negócio, como fluxo de caixa, administração de capital
          de giro, demonstração de resultado, retorno sobre investimentos,
          planejamento e controle orçamentário, dentre outros. Acompanhamento de
          reuniões mensais de Conselho.
        </>
      ),
      externalLink: {
        url: `/solucoes/controladoria/gestao-por-indicadores-kpis/`,
        sameOrigin: true,
        text: `Saiba mais`,
      },
    },
    {
      faIcon: faSearchDollar,
      title: `Apuração de Custos`,
      description: (
        <>
          Consultoria que tem como premissa melhorar a lucratividade.
          Estruturação de processos confiáveis de apuração e monitoramento de
          custos. Custeio fixo ou variável, determinação de margem de
          contribuição, análise de ociosidade de mão-de-obra e equipamentos,
          centros de custos, análise de ponto de equilíbrio, dentre outros.
        </>
      ),
      externalLink: {
        url: `/solucoes/controladoria/apuracao-de-custos/`,
        sameOrigin: true,
        text: `Saiba mais`,
      },
    },
    {
      faIcon: faMoneyBillWave,
      title: `Formação do Preço de Venda`,
      description: (
        <>
          Análise de preços adotados pela concorrência, margem de lucro por
          produto ou serviço, ponto de equilíbrio para sustentabilidade do
          negócio, estratégias de diferenciação, estruturação do processo de
          precificação levando em consideração os indicadores que compõem o
          preço, aumentar a competitividade diante do mercado.
        </>
      ),
      externalLink: {
        url: `/solucoes/controladoria/formacao-do-preco-de-venda/`,
        sameOrigin: true,
        text: `Saiba mais`,
      },
    },
    {
      faIcon: faBookOpen,
      title: `Estudos de Viabilidade`,
      description: (
        <>
          Análise de viabilidade econômico financeira para entender se um
          projeto é viável ou não. O estudo contempla projeção de receitas,
          custos, despesas e investimentos, fluxo de caixa, indicadores, retorno
          sobre o investimento, taxa mínima de atratividade e payback.
        </>
      ),
      externalLink: {
        url: `/solucoes/controladoria/estudos-viabilidade-financeira/`,
        sameOrigin: true,
        text: `Saiba mais`,
      },
    },
  ]

  const methodologyItems = [
    {
      count: 1,
      title: 'Diagnóstico',
      description: (
        <>
          O projeto se inicia a partir do primeiro contato com a equipe
          comercial, identificando as necessidades do cliente para elaboração do
          escopo do projeto. A cocriação dos objetivos do projeto é a melhor
          ferramenta para o sucesso. Necessário entender o contexto atual da
          organização para estabelecer aonde se quer chegar.
        </>
      ),
    },
    {
      count: 2,
      title: 'Metodologia Ágil',
      description: (
        <>
          Trabalhamos com metodologias ágeis de gestão de projetos através de
          uma ferramenta online de colaboração, proporcionando praticidade e
          transparência para que o cliente acompanhe as entregas por sprint e
          priorize o que é mais importante, diferentemente das metodologias
          tradicionais em que o resultado é entregue somente no final do
          projeto.
        </>
      ),
    },
    {
      count: 3,
      title: 'Implementação',
      description: (
        <>
          Nossa metodologia equilibra teoria e prática para alcançar os
          objetivos planejados. Estabelecemos planos de ação baseados na
          ferramenta 5W2H e utilizamos técnicas de gestão da mudança do
          Instituto HCMI para engajar e "puxar" as mudanças propostas, para que
          não fiquem apenas no papel.
        </>
      ),
    },
    {
      count: 4,
      title: 'Resultados',
      description: (
        <>
          Por que contratar nossa Consultoria? Trabalhamos com soluções
          personalizadas para desafios em comum das organizações. Nosso objetivo
          é entregar o resultado esperado e se não acreditarmos que o retorno
          será maior do que investimento, realmente não valerá a pena seguir.
        </>
      ),
    },
  ]

  return (
    <Layout location={location} mainFolder={location.pathname}>
      <SEO title="Controladoria - Valore Brasil" />
      <Slider slides={slides} />

      <section className={styles.solutions}>
        <div className="pt-3 pb-5">
          <div className="container">
            <SectionTitle
              title="Nosso Propósito"
              className="solutionsTitle"
              description="
              Propor soluções através do uso da Controladoria para melhorar o resultado
              do seu negócio, fazendo uma Gestão Compartilhada e melhorando o Processo
              Decisório, ligado à Meritocracia e Planos de Ação consistentes."
            />
          </div>
          <FeaturesSection items={featuresItems} />
        </div>

        <div className="container">
          <Divider />
        </div>

        <div className="py-5">
          <MethodologySection
            locationPath={location.pathname}
            items={methodologyItems}
          />
        </div>
      </section>

      <SuccessCases listCases={cases} isNumericCases={true} />
      <AboutUs />
      <Statistics />
    </Layout>
  )
}

export default Controladoria
